import React from 'react';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';

import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';

import * as ROUTES from '../../constants/routes';

const App = () => (
	<Router>
		<Route exact path={ROUTES.LANDING} component={LandingPage} />		
		<Route path={ROUTES.SIGN_UP} component={SignUpPage} />
		<Route path={ROUTES.SIGN_IN} component={SignInPage} />
	</Router>
);
	
	export default App;