import React from 'react';


const LandingPage = () => {
    return (
        <section class="hero is-primary is-fullheight">
            <div class="hero-head">
                <nav class="navbar is-hidden">
                <div class="container">
                    <div class="navbar-brand">
                    <a href="#" class="navbar-item">
                        <img src="" alt=""/>
                    </a>
                    <span class="navbar-burger burger" data-target="navbarMenuHeroA">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    </div>
                    <div id="navbarMenuHeroA" class="navbar-menu">
                        <div class="navbar-end">
                            <a class="navbar-item is-active">
                            Home
                            </a>
                            <a class="navbar-item">
                            Examples
                            </a>
                            <a class="navbar-item">
                            Documentation
                            </a>
                            <span class="navbar-item">
                            <a class="button is-primary is-inverted">
                                <span class="icon">
                                <i class="fab fa-github"></i>
                                </span>
                                <span>Download</span>
                            </a>
                            </span>
                        </div>
                    </div>
                </div>
                </nav>
            </div>

            <div class="hero-body">
                <div class="container has-text-centered">
                <h1 class="title">
                    Venues
                </h1>
                <h2 class="subtitle">
                    Comming soon...
                </h2>
                </div>
            </div>

            <div class="hero-foot is-hidden">
                <nav class="tabs">
                <div class="container">
                    <ul>
                    <li class="is-active"><a>Overview</a></li>
                    <li><a>Modifiers</a></li>
                    <li><a>Grid</a></li>
                    <li><a>Elements</a></li>
                    <li><a>Components</a></li>
                    <li><a>Layout</a></li>t
                    </ul>
                </div>
                </nav>
            </div>
        </section>
    )
}


export default LandingPage;